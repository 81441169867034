@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* controls the font smoothing, eg: Chrome displays font bolder than they actually are */
}

.btn {
  @apply min-h-8 h-9 font-semibold;
}
.btn span > svg {
  @apply inline-block !-ml-1;
}
.btn svg:only-child {
  @apply !m-0;
}
/**/
.btn-sm {
  @apply h-8 px-3 text-xs leading-6;
}
.btn-sm svg {
  @apply w-4 h-4;
}
.btn-sm svg:first-child {
  @apply mr-[6px];
}
.btn-sm svg:last-child {
  @apply ml-[6px];
}
/**/
.btn-md {
  @apply h-9 px-4 text-sm leading-6;
}
.btn-md svg {
  @apply w-[18px] h-[18px];
}
.btn-md svg:first-child {
  @apply mr-2;
}
.btn-md svg:last-child {
  @apply ml-2;
}
/**/
.btn-lg {
  @apply h-12 px-4 text-base leading-8;
}
.btn-lg svg {
  @apply w-6 h-6;
}
.btn-lg svg:first-child {
  @apply mr-2;
}
.btn-lg svg:last-child {
  @apply ml-2;
}
/**/
.btn-xl {
  @apply h-16 px-6 text-lg leading-8;
}
.btn-xl svg {
  @apply w-6 h-6;
}
.btn-xl svg:first-child {
  @apply mr-[10px];
}
.btn-xl svg:last-child {
  @apply ml-[10px];
}
/**/

.modal {
  transition: opacity 0.25s ease;
}

body.modal-active {
  overflow-x: hidden;
  overflow-y: visible !important;
}

.step-tile>div {
  width: 100%;
  height: 100%;
}

.tippy-box .hs-inspector {
  color: initial !important;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

#arrow,
#arrow::before {
  position: absolute;
  width: 12px;
  height: 12px;
  background: inherit;
}

#arrow {
  visibility: hidden;
}

#arrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}

#arcade-button-popover[data-placement^='top'] > #arrow {
  bottom: -6px;
}

/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #29d;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #29d, 0 0 5px #29d;
  opacity: 1.0;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
      -ms-transform: rotate(3deg) translate(0px, -4px);
          transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: #29d;
  border-left-color: #29d;
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
          animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0%   { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}
@keyframes nprogress-spinner {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}



table.query-table {
  border-collapse: separate;
  border-spacing: 0 10px;
  margin-top: -10px; /* correct offset on first border spacing if desired */
}
table.query-table td {
  border-width: 1px;
  border-color:  #F2F3F5;
  border-top-style: solid;
  border-right-style: none;
  border-left-style: none;
  border-bottom-style: solid;
  padding: 10px;
  text-align: center;
}
table.query-table tr.excluded-row td {
  color: #8b8b8b
}
table.query-table td:first-child {
  border-left-style: solid;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
table.query-table td:last-child {
  border-right-style: solid;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

/* Hide weird empty box in post-login state */
/* https://github.com/firebase/firebaseui-web/issues/121 */
#get-started .firebaseui-id-page-callback {
  height: 2px;
  overflow: hidden;
  display: block;
  min-height: 2px;
  box-shadow: none;
}
